// Sassファイルの読み込み
import "../css/style.scss";

// core version + navigation, pagination modules:
import Swiper, { Autoplay } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// init Swiper:
const swiper = new Swiper('.swiper', {
  // configure Swiper to use modules
  slidesPerView: 2,
  spaceBetween: 16,
  centeredSlides: true,
  loop: true,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
});